body {
    font-family: sans-serif;
}

button {
    touch-action: manipulation;
}

/* Bootstrap overrides */
.btn-primary,
.btn-primary:disabled {
    background-color: #ed5623;
    border-color: #ed5623;
}
  
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgb(212, 53, 0, 0.25);
}
.btn-primary:active,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):hover {
    background-color: #d43500;
    border-color: #d43500;
}

.form-group {
    margin-bottom: .5rem;
}

.row {
    margin-right: 0;
    margin-left: 0;
}


/* Layout */
.app-container {
    display: flex;
    flex-wrap: nowrap;
}

.grid-container {
    margin: 15px 15px 15px 365px;
    width: calc(100vw - 380px);
    max-height: 100vh;
    max-height: -webkit-fill-available;
    position: fixed;
}


/* Controls */
.actions {
    display: flex;
    justify-content: flex-end;
    padding: 0 15px;
}

.controls {
    height: 100%;
    position: fixed;
    padding: 1rem 0;
    width: 350px;
    overflow-y: scroll;
    z-index: 10;
    background: #fff;
}

.controls::-webkit-scrollbar {
  width: 0.25rem;
}

.controls::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.controls::-webkit-scrollbar-thumb {
  background: slategrey;
  border: 0.125rem solid slategrey;
  border-radius: 10rem;
}

.controls::-webkit-scrollbar-corner {
  background: slategrey;
}

/* Stage */
#stage {
    counter-reset: item;
    color: #000;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transform-origin: left top;
    
    /* set inline via react */
    background: red;
    width: 0;
    height: 0;
    padding: 0;
}

.stage-row {
    display: flex;
    /* background: #ccc; */
    flex-direction: row;
    flex-grow: 1;
    align-items: stretch;
}

.stage-row:first-child .item:first-child {
    margin-top: 0px !important;
}

.stage-row:last-child .item:last-child {
    margin-bottom: 0px !important;
}

.stage-column:first-child .item {
    margin-left: 0px !important;
}

.stage-column:last-child .item {
    margin-right: 0px !important;
}

.stage-column {
    display: flex;
    /* outline: 0px solid red; */
    flex-grow: 1;
    flex-direction: column;
    align-items: stretch;
}

.stage-item.stage-item__visible:before {    
    content: "";
    background: no-repeat url('./16-9.png') center center;
    background-size: contain;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.75;
}

.stage-item.stage-item__visible:after {
    counter-increment: item;
    content: counter(item, upper-alpha);
    font-family: Arial, sans-serif;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    position: absolute;
    width: 100%
}

.stage-item.stage-item__hidden {
    visibility: hidden;
}

.stage-item {
    font-family: Arial, sans-serif;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-grow: 1;
    align-items: center;
    position: relative;
    
    /* set inline via react */
    background: green;
    margin: 0px;
}


/* Stepper */
.stepper-label {
    margin-bottom: 0;
}
.stepper {
    display: flex;
    align-items: center;
}
  
.stepper button {
    border-radius: 100%;
    width: 32px;
    height: 32px;
    padding: 0;
}
  
.stepper span {
    width: 32px;
    text-align: center;
}
